<!--
 * @Description: 基本信息
 * @Author: zhang zhen
 * @Date: 2023-02-14 21:11:10
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-22 11:09:17
 * @FilePath: /page-sass/src/views/requirement/requirementList/modules/basicInfo.vue
-->
<template>
  <div class="RequirementConfirmation">
    <div class="RequirementConfirmation-content">
      <div class="RequirementConfirmation-content-item" style="margin-bottom: 15px">
        <h2 class="RequirementConfirmation-title"><span class="title">基本信息</span></h2>
        <a-descriptions :column="2" bordered style="margin-bottom: 25px;">
          <a-descriptions-item label="供应商参与数量">
            {{ baseInfo.participantCount || '-' }}
          </a-descriptions-item>

          <a-descriptions-item label="需求类型">
            {{ baseInfo.purchaseTypeDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="报价截止日期">
            {{ baseInfo.quotationDeadline || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="付款方式">
            {{ baseInfo.paymentMethodDictName || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <h2 class="RequirementConfirmation-title"><span class="title">采购商信息</span></h2>
        <a-descriptions :column="2" bordered style="margin-bottom: 25px;">
          <a-descriptions-item label="采购商名称" :span="2">
              {{ basicInfo.businessName || '个人' }}
            </a-descriptions-item>
          <a-descriptions-item label="产品类别">
            {{ basicInfo.productCategoryDictName || '-' }}
          </a-descriptions-item>

          <a-descriptions-item label="年包材需求总额">
            {{ basicInfo.packagingCostTotalDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="单次最小下单量">
            {{ basicInfo.minOrderCountDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="设计要求">
            {{ basicInfo.designDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="打样测试">
            {{ basicInfo.sampleTestDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="认证要求">
            {{ basicInfo.authMethodDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="其他服务">
            {{ basicInfo.otherServiceDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="下单周期">
            {{ basicInfo.orderCycleDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="要货方式">
            {{ basicInfo.purchasingMethodDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="供货区域">
            {{ basicInfo.providerAreaDictName || '-' }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <!-- 供应商 -->
      <template>
        <div class="RequirementConfirmation-content-item" style="margin-top: 15px">
          <h2 class="RequirementConfirmation-title"><span class="title">详细要求</span></h2>
          <!-- v-if="formModel == 1" -->
          <template v-if="formModel == 1">
            <splitLine label="瓦楞纸包装" />
            <a-descriptions :column="2" bordered>
              <a-descriptions-item label="包装用途">
                {{ baseInfo.wrapTypeDictName || '-' }}
              </a-descriptions-item>

              <a-descriptions-item label="瓦楞箱型">
                {{ baseInfo.purchaseTypeDictName || '-' }}
              </a-descriptions-item>
            </a-descriptions>
            <splitLine label="上传文件" />
            <div class="excel-box">
              <div class="top-box">
                <img class="excel-icon" src="~@/assets/excel.png" alt="" />
                <!-- <span class="fileName">{{ excelName }}</span> -->
                <a @click="handleDownLoadFile(baseInfo.templateFile)">下载</a>
              </div>
            </div>
            <splitLine label="附件" />
            <div class="files-list">
              <div class="files-list-item" v-for="item in fileList" :key="item.name">
                <div class="cover">
                  <img :src="item.url" alt="" class="cover" v-if="item.isImage" />
                  <img src="~@/assets/normalFile.jpg" alt="" class="cover" v-else />
                </div>
                <div class="option-area">
                  <img src="~@/assets/view.png" alt="" title="预览" @click="handleDownLoadFile(item.url)" />
                  <div class="split-line"></div>
                  <img src="~@/assets/dowload.png" alt="" title="下载" @click="handleDownLoadFile(item.url)" />
                </div>
                <div class="info-card inside">
                  <div class="sub_title">{{ item.fileName }}</div>
                  <!-- <div class="labels">文件大小: -K</div>
                            <div class="labels">上传日期: -</div> -->
                </div>
              </div>
            </div>

          </template>
          <template v-else>
            <!-- <a-button type="link" class="edit-btn" @click="handleEdit('CompleteInformation')"
              :disabled="!purchaseItem.length || infoStatus == '2'" v-if="isMine == 1 && !isOut"><img
                src="~@/assets/edit.png" /><span>编辑</span></a-button> -->
            <a-tabs type="card">
              <a-tab-pane key="1" :tab="tabTitleList[index] ? tabTitleList[index] : `散件${index + 1}`" v-for="(i, index) in purchaseItem" :key="index">
                <RequirementInfoView :formItemDtoList="i.formItemDtoList" />
                <splitLine label="附件" v-show="i.fileList && i.fileList.length" />
                <div class="files-list">
                  <div class="files-list-item" v-for="item in i.fileList" :key="item.name">
                    <div class="cover">
                      <img :src="item.url" alt="" class="cover" v-if="item.isImage" />
                      <img src="~@/assets/normalFile.jpg" alt="" class="cover" v-else />
                    </div>
                    <div class="option-area">
                      <img src="~@/assets/view.png" alt="" title="预览" @click="handleDownLoadFile(item.url)" />
                      <div class="split-line"></div>
                      <img src="~@/assets/dowload.png" alt="" title="下载" @click="handleDownLoadFile(item.url)" />
                    </div>
                    <div class="info-card inside">
                      <div class="sub_title">{{ item.fileName }}</div>
                      <!-- <div class="labels">文件大小: -K</div>
                            <div class="labels">上传日期: -</div> -->
                    </div>
                  </div>
                </div>
              </a-tab-pane>
            </a-tabs>
            <template v-if="fileList && fileList.length">
              <splitLine label="附件" />
              <div class="files-list">
                <div class="files-list-item" v-for="item in fileList" :key="item.name">
                  <div class="cover">
                    <img :src="item.url" alt="" class="cover" v-if="item.isImage" />
                    <img src="~@/assets/normalFile.jpg" alt="" class="cover" v-else />
                  </div>
                  <div class="option-area">
                    <img src="~@/assets/view.png" alt="" title="预览" @click="handleDownLoadFile(item.url)" />
                    <div class="split-line"></div>
                    <img src="~@/assets/dowload.png" alt="" title="下载" @click="handleDownLoadFile(item.url)" />
                  </div>
                  <div class="info-card inside">
                    <div class="sub_title">{{ item.fileName }}</div>
                    <!-- <div class="labels">文件大小: -K</div>
                            <div class="labels">上传日期: -</div> -->
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </template>
    </div>
    <template v-if="needOption && (basicInfo.status != 0 && basicInfo.status != 1 && basicInfo.status != 2)">
      <div class="cover-box">
        <a-button type="primary" @click="handleCloseRequirement">关闭需求</a-button>
      </div>
    </template>
    <div class="RequirementConfirmation-content-item" style="margin-top: 20px" v-if="needSteps">
      <h2 class="RequirementConfirmation-title"><span class="title">需求动态</span></h2>
      <EmptyArea v-if="!processList.length" description="暂无需求动态" style="height: 250px" />
      <div class="process-content" v-if="processList.length">
        <a-timeline>
          <a-timeline-item color="gray" v-for="(i, index) in processList" :key="index">

            <div class="time-line-card">
              <!-- <div class="tag">合作</div> -->
              <div class="time-line-content">
                <div class="content">{{ i.updateReason }}</div>
                <span class="reason" v-if="i.content">原因：{{ i.content || '' }}</span>
              </div>
            </div>
            <span class="time">{{ i.createTime | formatDate }}</span>
          </a-timeline-item>
        </a-timeline>
        <a-row class="basicInfo-form-item" style="padding: 0px; margin-top: -18px"
          v-if="processList && processList.length > 15">
          <a-col :span="24" style="text-align: left">
            <a-button type="link" v-if="!showMoreProcess" @click="showMoreProcess = true"
              style="padding-right: 0"><span>展开</span><a-icon type="caret-down" style="margin-left: 4px" /></a-button>
            <a-button type="link" v-else @click="showMoreProcess = false"
              style="padding-right: 0"><span>收起</span><a-icon type="caret-up" style="margin-left: 4px" /></a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <template v-if="needOption">
      <!-- <div class="RequirementConfirmation-content-item last-label" style="margin-top: 30px" v-if="infoStatus == 3">
        <a-form-model :colon="false" style="width: 100%">
          <a-form-model-item :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }">
            <img src="~@/assets/priceTitle.png" alt="" class="price-title" slot="label" />
            <a-input-number :min="0" placeholder="各需求单的单价*数量总和" v-model="priceBudget" class="numberInput"
              :formatter="(value) => (value ? `${value}元`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '')" />
          </a-form-model-item>
        </a-form-model>
        <img src="~@/assets/money.png" class="moneyIcon" />
      </div> -->
      <div class="btns" v-if="infoStatus == 3">
        <a-button type="primary" @click="handleSubmit" :loading="loading"
          style="width: 120px; height: 38px; font-weight: 500; margin-left: 8px;margin-top: 15px;">发布</a-button>
      </div>
    </template>

  </div>
</template>

<script>
import { postAction, getAction, postQueryAction } from '@/api/manage'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import VueViewer from 'v-viewer'
import Vue from 'vue'
import RequirementInfoView from '@/components/plugins/RequirementInfoView.vue'
import splitLine from '@/components/plugins/splitLine.vue'

Vue.use(VueViewer)
export default {
  name: 'BasicInfo',
  components: {
    EmptyArea,
    RequirementInfoView,
    splitLine
  },
  props: {
    purchaseId: {
      type: String,
      default: '',
    },
    formType: {
      type: String,
      default: 'product',
    },
    infoStatus: {
      type: [Number, String],
      default: 1,
    },
    needLoadNow: {
      type: Boolean,
      default: false,
    },
    needSteps: {
      type: Boolean,
      default: true,
    },
    needOption: {
      type: Boolean,
      default: false,
    },
    isOut: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      priceBudget: 0,
      showMoreProcess: false,
      showMore: false,
      baseInfo: {},
      purchaseItem: [],
      formModel: 2,
      processList: [],
      excelName: '',
      isMine: 1,
      fileList: [],
      showFile: true,
      basicInfo: {},
      hasJoin: 0,
      tabTitleList: [],
    }
  },
  created() {
    this.needLoadNow && this.handleLoadData()
    if (this.$route.query.hasJoin) {
      this.hasJoin = this.$route.query.hasJoin
    } else {
      this.hasJoin = 0
    }
    if (this.$route.query.isMine) {
      this.isMine = this.$route.query.isMine
    } else {
      this.isMine = 1
    }
  },
  methods: {
    show(url) {
      this.$viewerApi({
        images: [url],
      })
    },
    handleCloseRequirement() {
      getAction(`/purchases/close/${this.purchaseId}`).then(res => {
        const { success, message } = res
        if (success) {
          this.$message.success(message)
          this.$router.go(-1)
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleSubmit() {
      // if (!this.priceBudget) return this.$message.warning('请输入您的整体预算价格')
      //  priceBudget: this.priceBudget
      this.loading = true
      postAction(`/purchases/publish/${this.purchaseId}`, { }).then((res) => {
        const { success, message } = res
        this.loading = false
        if (success) {
          this.$message.success({
            content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">{`${message}`}</span>,
            icon: (h) => <img src="/pass.png" width="21" height="21" />,
          })
          this.$router.go(-1)
        } else {
          this.$message.warning({
            content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">{`${message}`}</span>,
            icon: (h) => <img src="/noPass.png" width="21" height="21" />,
          })
        }
      }).catch(e => {
        this.loading = false
      })
    },
    handleLoadData() {
      postAction(`/purchases/showPurList/${this.purchaseId}`).then((res) => {
        const { success, data, message } = res
        if (success) {
          const { baseInfo, forms, newFlag } = data
          this.$emit('setInfo', newFlag || false)
          postQueryAction('/business/queryByBusinessId', {
            businessId: baseInfo.businessId
          }).then(res => {
            const { success, data } = res
            if (success) {
              const { capacity, supplierPurchasePlan = {}, tradeIdentity = {}, businessName } = data;
              this.basicInfo = Object.assign({ businessName }, tradeIdentity == 0 ? supplierPurchasePlan : capacity);
            }
          })
          !forms || forms.length == 0 && (this.formModel = 1)

          if (baseInfo.uploadFile) {
            let fileList = baseInfo.uploadFile.split(',')
            fileList.map((i) => {
              let fileName = i.includes('/images/') ? i.split('/images/')[1] : i.split('/file/')[1]
              this.fileList.push({
                fileName,
                url: i,
                isImage: fileName.includes('.jpg') || fileName.includes('.png') || fileName.includes('.jpeg'),
              })
            })
          }
          this.priceBudget = 0
          this.purchaseItem = []
          forms.map(i => {
            if (i.formItemDtoList.length) {
              const { wrapTypeDictName } = i.formItemDtoList[0];
              this.tabTitleList.push(`${ baseInfo.purchaseTypeDictName } - ${ wrapTypeDictName }`)
            }
            // 计算合计金额
            let num = 0, price = 0;
            let targetNumItem = i.formItemDtoList.find(item => item.itemConfig.fieldFlag == 'NUM')
            let targetPriceItem = i.formItemDtoList.find(item => item.itemConfig.fieldFlag == 'PRICE')
            if (targetNumItem && targetPriceItem) {
              num = targetNumItem.itemValue || 0
              price = targetPriceItem.itemValue || 0
            }
            this.priceBudget += Number(num) * Number(price);
            // 上传图片回显示
            let baseUploadItem = i.formItemDtoList.find(item => item.itemConfig.componentType == 'FILE'), fileList = [];
            this.showFile = !!baseUploadItem // 显示文件上传
            if (baseUploadItem && baseUploadItem.itemValue) {
              let fileArray = baseUploadItem.itemValue.split(',')
              fileArray.map((i) => {
                let fileName = i.includes('/images/') ? i.split('/images/')[1] : i.split('/file/')[1]
                fileList.push({
                  fileName,
                  url: i,
                  isImage: fileName.includes('.jpg') || fileName.includes('.png') || fileName.includes('.jpeg'),
                })
              })
            }

            let formItemDtoList = i.formItemDtoList.filter(item => item.itemConfig.componentType != 'FILE')
            this.purchaseItem.push({
              formItemDtoList,
              fileList
            })
          })
          this.baseInfo = baseInfo
        } else {
          this.$message.warning(message)
        }
      })
      if (!this.needSteps) return
      getAction(`logAction/getLogByBusId/${this.purchaseId}`).then((res) => {
        const { success, data } = res
        if (success) {
          this.processList = data
        }
      })
    },
    handleDownLoadFile(url) {
      window.open(url)
    },
    handleEdit(tabKey) {
      this.$router.push(
        `/PublishingRequirements?formType=${this.formType}&stepKey=${tabKey}&purchaseId=${this.purchaseId}`
      )
    },
  },
}
</script>

<style lang="less" scoped>
.RequirementConfirmation {
  &-title {
    font-size: 15px;
    color: #605f5f;
    margin-bottom: 24px;
    height: 38px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;

    &:before {
      background: #ff6e2d;
      display: inline-block;
      content: '';
      width: 5px;
      height: 16px;
      margin-right: 8px;
    }

    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      margin-right: 10px;
    }

    .subTitle {
      font-weight: 400;
      font-size: 14px;
      color: #8d8e99;
    }
  }

  &-content {
    &-item {
      width: 100%;
      background: #fff;
      position: relative;

      .edit-btn {
        position: absolute;
        top: 4px;
        right: -15px;

        img {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
  }

  ::v-deep .ant-form-item-label {
    line-height: 22px;

    label {
      color: #626161 !important;
    }
  }

  ::v-deep .ant-form-item-control {
    line-height: 22px;
    color: #131212;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subTitle {
    width: calc(100% - 40px);
    font-size: 15px;
    color: #ff6e2d;
    padding-bottom: 8px;
    border-bottom: 1px solid #efefef;
    margin: 0 auto 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tag-title {
      padding: 2px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background: #fff2ea;
      border-radius: 2px;
    }

    img {
      width: 15px;
      margin-right: 4px;
    }

    span {
      line-height: 22px;
      font-size: 14px;
    }
  }

  .basicInfo-form {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 18px 0 4px;

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    &-item {
      padding: 0 20px;
    }
  }

  ::v-deep .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-btn-link[disabled] img {
    filter: grayscale(100%);
  }

  .price-title {
    width: 107px;
    margin-top: 8px;
  }

  .process-content {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 24px;

    ::v-deep .ant-timeline-item-tail {
      border: 0.5px dashed #dcdcdc;
    }

    ::v-deep .ant-timeline-item-head {
      width: 5px;
      height: 5px;
      background: #d7d7db;
      border: none;
      left: 2px;
      top: 3px;
    }

    .content {
      padding-top: 5px;
      color: #000000;
      font-size: 14px;
      line-height: 20px;
    }

    .time {
      font-size: 14px;
      line-height: 20px;
      color: #8d8e99;
    }
  }
}

.ant-descriptions {
  // background-color: #f7f8fa;
  // padding: 16px 25px 0;
  // border-radius: 8px;

  &.tag_2 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

::v-deep .ant-descriptions-item-label {
  color: #595959;
}

::v-deep .ant-descriptions-item-content {
  color: #131212;
}

::v-deep .ant-radio-disabled+span {
  color: #262626;
}

.card-view {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px 25px;

  &-item {
    width: calc((100% - 50px) / 3);
    height: 144px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px dashed #e8e9eb;
    padding: 15px;
    display: flex;
    position: relative;

    .cover {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 15px;
      cursor: pointer;

      &:hover {
        &+.option-area {
          opacity: 1;
          z-index: 10;
        }
      }
    }

    .option-area {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.65);
      position: absolute;
      left: 15px;
      top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      z-index: -1;

      &:hover {
        opacity: 1;
        z-index: 10;
      }

      img {
        height: 16px;
        cursor: pointer;
      }

      .split-line {
        width: 1px;
        height: 14px;
        background-color: #fff;
        margin: 0 15px;
      }
    }
  }

  .info-card {
    &.inside {
      flex: 1 0 0;
      min-width: 0;
    }

    .sub_title {
      font-size: 14px;
      font-weight: 400;
      color: #131212;
      line-height: 22px;
      margin-bottom: 8px;
      overflow: hidden;
      .text-ellipsis-multi(2);
    }

    div.labels {
      font-size: 12px;
      color: #8d8e99;
      line-height: 20px;
      font-weight: 400;
    }
  }
}

.desc-title {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #EFEFEF;
  padding-bottom: 12px;
  margin-bottom: 15px;

  span.title {
    font-size: 14px;
    color: #131212;
  }
}

div.file-card {
  width: max-content;
  height: 64px;
  background: #fff;
  border-radius: 2px;
  padding: 8px 12px 8px 12px;
  margin-bottom: 8px;

  &-title {
    margin-bottom: 15px;

    img.icon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    .fileName {
      color: #1d2129;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &-size {
    font-weight: 400;
    color: #8d8e99;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    padding-left: 22px;
  }
}

.coverBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.last-label {
  width: 100%;
  height: 102px;
  background-image: url('~@/assets/longerBG.png');
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  .ant-form-item {
    margin-bottom: 0;
  }

  ::v-deep .ant-input {
    border-color: #ff9a57;
  }

  ::v-deep .ant-input-suffix {
    color: #131212;
  }

  .moneyIcon {
    position: absolute;
    bottom: 0;
    right: 19px;
  }
}

.numberInput {
  width: 320px;
  border-color: #ff7d2f;
  height: 38px;
  line-height: 38px;

  ::v-deep .ant-input-number-input {
    font-size: 18px;
    color: #131212;
    font-weight: 500;
  }
}

.btns {
  text-align: center;
}

.cover-box {
  margin-top: 15px;
  text-align: right;
}

@import '~@/styles/form.less';
</style>
